<template>
  <div class="add-widget">
    <a href="https://online.caliente.mx/page?member=cimarrones&campaign=DEFAULT&channel=DEFAULT&zone=60503210&lp=0" target="_blank" rel="noopener noreferrer">
      <img
        src="../../assets/calienteMO.gif"
        alt="vol"
        class="sponsor-add-dr"
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
