<template>
  <div class="last-galleries-cont">
    <div class="ugc-header">
      <h3 class="t">ÚLTIMAS <span>GALERÍAS</span></h3>
      <a href="/galeria">VER TODAS</a>
    </div>

    <GalleryModal v-show="isModalVisible9" @close="closeModal9">
      <template v-slot:header>
        GALERÍA
      </template>

      <template v-slot:body> 
        <div class="gallery-item" v-for="imadata in modalData9" :key="imadata.id">
          <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata.image" alt="image" @click="showModal10(imadata)"/>
        </div> 
      </template>
    </GalleryModal>

    <GalleryImgModal v-show="isModalVisible10" @close="closeModal10">
      <template v-slot:body> 
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalData10.image" alt="image"/>
      </template>
    </GalleryImgModal>


    <div
      class="news-widget"
      v-for="lgal in lastgallerydata.slice(0, 3)"
      :key="lgal.id" 
      @click="showModal9(lgal.imagenes)"
    >
      <a>
        <img
          :src="url + '/get-imagesgallery-image/' + lgal.image"
          alt="news"
          class="news-img"
        />
      </a>
      <div class="day-sm">
        <h5 class="day-ago">
          HACE {{ newsdateago(dataTransform(lgal.dateimg)) }}
        </h5>
        <!--<div class="sm-cont-n-w">
          <p>COMPARTIR EN:</p>
          <ShareNetwork
            network="facebook"
            :url="
              'https://cimarronesdesonora.xyz/gallery/' +
                filterTitle(lgal.title)
            "
            :title="'titulo'"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/fb-share.png" alt="fb" class="sm-icon-w" />
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            :url="
              'https://cimarronesdesonora.xyz/gallery/' +
                filterTitle(lgal.title)
            "
            :title="'titulo'"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/tw-share.png" alt="ins" class="sm-icon-w" />
          </ShareNetwork>
          <ShareNetwork
            network="linkedin"
            :url="
              'https://cimarronesdesonora.xyz/gallery/' +
                filterTitle(lgal.title)
            "
            :title="'titulo'"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/linkein.png" alt="ins" class="sm-share" />
          </ShareNetwork>
        </div>-->
      </div>
      <a >
        <h3 class="titulo-n-w">{{ lgal.title }}</h3>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import GalleryModal from "../../components/GalleryModal.vue";
import GalleryImgModal from "../../components/GalleryImgModal.vue";

export default {
  components: {
    GalleryModal,
    GalleryImgModal,
  },
  data() {
    return {
      isModalVisible9: false,
      modalData9: null,
      isModalVisible10: false,
      modalData10: null,
    };
  },
  created() {
    this.getGallerys4();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    lastgallerydata() {
      let gallery = this.$store.getters["gallery/data6"];

      let elekipo = gallery.sort(
        (a, b) => {return new Date(b.date) - new Date(a.date);}
      );
      return elekipo;     },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("gallery", ["getGallerys4"]),

    showModal9(data) {
      this.isModalVisible9 = true;
      this.modalData9 = data;
    },
    closeModal9() {
      this.isModalVisible9 = false;
      this.modalData9 = null;
    },

    showModal10(data) {
      this.isModalVisible10 = true;
      this.modalData10 = data;
    },
    closeModal10() {
      this.isModalVisible10 = false;
      this.modalData10 = null;
    },

    dataTransform: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function(date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD")
        .locale(es)
        .fromNow(true);
      return newsdata;
    },

    getGallerys4: async function() {
      let result1 = await this.$store.dispatch("gallery/getGallerys4", {
        option: "galeria",
      });
      this.info = result1;
      return result1;
    },

    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>
