<template>
  <div class="last-news-cont">
    <div class="lnc-header">
      <h3 class="t">ÚLTIMAS <span>NOTICIAS</span></h3>
      <a href="/blog">VER TODAS</a>
    </div>
    <div
      class="news-widget"
      v-for="lnww in blogdata1.slice(0, 3)"
      :key="lnww.id"
    >
      <a :href="'/blog/titulo/' + lnww.url">
        <img
          :src="url + '/get-blog-image/' + lnww.image"
          alt="news"
          class="news-img"
        />
      </a>
      <div class="day-sm">
        <h5 class="day-ago">
          HACE {{ newsdateago(dataTransform(lnww.date)) }}
        </h5>
        <div class="sm-cont-n-w">
          <p>COMPARTIR EN:</p>
          <ShareNetwork
            network="facebook"
            :url="
              'https://cimarronesdesonora.xyz/blog/titulo/' +
                lnww.url
            "
            :title="lnww.title"
            :quote="lnww.title"
                hashtags="CimarronesFC"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/fb-share.png" alt="fb" class="sm-icon-w" />
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            :url="
              'https://cimarronesdesonora.xyz/blog/titulo/' +
                lnww.url
            "
            :title="lnww.title"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/tw-share.png" alt="ins" class="sm-icon-w" />
          </ShareNetwork>
          <ShareNetwork
            network="linkedin"
            :url="
              'https://cimarronesdesonora.xyz/blog/titulo/' +
                lnww.url
            "
            :title="lnww.title"
            description="Cimarrones de Sonora"
          >
            <img src="../../assets/linkein.png" alt="ins" class="sm-icon-w" />
          </ShareNetwork>
        </div>
      </div>
      <a :href="'/blog/titulo/' + lnww.url">
        <h3 class="titulo-n-w">{{ lnww.title }}</h3>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {};
  },
  metaInfo(){
    return{
      title: 'Noticia Cimarrones FC',
      meta: [
        { property:"og:title", name:"title", content:"Noticia Cimarrones FC"},
        { property:"og:image", name:"image", content:"https://cimarronesdesonora.xyz/img/Cimarrones-mx.db56f6bd.png" }
      ],
    }
  },
  created() {
    this.findAll();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    blogdata1() {
      let match = this.$store.getters["blog2/data"];
      let eltitulo = this.$route.params.title.replaceAll('-', ' ')
      let encontrado = false;
      for(let i = 0; i < match.length; i++){
        if(encontrado==false){
          if(match[i].title = eltitulo){
            encontrado=true;
            match.splice(i, 1);
            let elekipo = match.sort(
              (a, b) => {return new Date(b.date) - new Date(a.date);}
            );
            return elekipo;           }
        }
      } 
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("blog2", ["findAll"]),
    ...mapActions("blog2", ["getImageFile"]),

    dataTransform: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function(date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD")
        .locale(es)
        .fromNow(true);
      return newsdata;
    },

    findAll: async function() {
      let result1 = await this.$store.dispatch("blog2/findAll", {
        option: "blog",
      });
      this.info = result1;
      return result1;
    },

    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>
<style scoped>
.last-news-cont a:hover {
    color: white!important;
}
</style>