<template>
  <div class="add-widget">
    <a href="">
      <img
        src="../../assets/sponsor-volaris.png"
        alt="vol"
        class="sponsor-add-dr"
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
