<template >
    <div id="blog-post">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
             
                <HeaderHome :name="user.fullname" /> 
        </div>

        <div class="blog-post-cont">
            <div>
                <PostSection />
            </div>
            <div class="widgets-cont">
                    <LastNewsWidget />
                    <AddW2 />
                    <LastGalleriesW />
                    <!--<AddW/>-->
            </div>
        </div>

         <div id="footerSection">
             <Footer />
         </div>
        
    </div>
</template>
<script>
import Footer from '../components/footer';
import HeaderHome from '../components/HeaderHome';
import LastNewsWidget from '../components/widgets/LastNewsW';
import LastGalleriesW from '../components/widgets/LastGalleriesW';
import AddW from "../components/widgets/AddW";
import AddW2 from "../components/widgets/AddW2";
import PostSection from '../components/PostSection';
import {mapState,mapActions,mapMutations} from 'vuex';

export default {
    components: {
        HeaderHome,
        Footer,
        LastNewsWidget,
        LastGalleriesW,
        AddW,
        AddW2,
        PostSection
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },

    },
}
</script>