<template>
  <div>
    <div class="post-cont" v-for="lnw in blogdata" :key="lnw.id">
      <div class="header-post-cont">
        <div class="titulo-header-cont pc">
          <h5 class="date-post">
            PUBLICADO EL {{ convertdate(lnw.date) }}
          </h5>
          <h1 class="titulo-post">{{ lnw.title }}</h1>
        </div>
        <img
          :src="url+'/get-blog-image/'+lnw.image"
          alt="###"
          class="header-post-img"
        />
      </div>

      <div class="post-text-cont">
        <div class="titulo-header-cont mo">
          <h5 class="date-post">
            PUBLICADO EL {{convertdate(lnw.date)}}
          </h5>
          <h1 class="titulo-post">{{lnw.title}} </h1>
        </div>
        <div class="authors-cont">
          <h5 class="autor-text">POR {{lnw.autor}}</h5>
          <div class="share-in">
            <p>COMPARTIR EN</p>
            
            <div class="sm-share-in">

              <ShareNetwork 
                network="facebook"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="filterTitle(lnw.title)"
                description="Descripcion"
                :quote="tituloFiltrado()"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/fb-share.png" alt="fb" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="filterTitle(lnw.title)"
                :description="tituloFiltrado()"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/tw-share.png" alt="ins" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="tituloFiltrado()"
                :description="tituloFiltrado()"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/linkein.png" alt="ins" class="sm-share" />
              </ShareNetwork>
            </div>
          </div>
        </div>
        <div class="post-text" v-html="lnw.content"></div>

            <div class="sm-share-in mobile">
              <h3 class="share-title">COMPARTIR EN </h3>
              <div class="cont-sm-s">
                <ShareNetwork 
                network="facebook"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="filterTitle(lnw.title)"
                description="Descripcion"
                :quote="tituloFiltrado()"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/fb-share.png" alt="fb" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="filterTitle(lnw.title)"
                :description="tituloFiltrado()"
                hashtags="CimarronesFC"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/tw-share.png" alt="ins" class="sm-share" />
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="'https://cimarronesdesonora.xyz/blog/titulo/' + lnw.url"
                :title="tituloFiltrado()"
                :description="tituloFiltrado()"
                :media="require('../assets/videobanner.png')"
              >
                <img src="../assets/linkein.png" alt="ins" class="sm-share" />
              </ShareNetwork>
                </div>
            </div>
      </div>
    </div>
  </div>
</template>

<script src="/dist/vue-social-sharing.js"></script>
<script>
import {mapActions} from 'vuex';
import es from 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'

export default {
  metaInfo(){
    return{
      title: 'Noticia Cimarrones FC',
      meta: [
        { property:"og:title", name:"title", content:"Noticia Cimarrones FC"},
        { property:"og:image", name:"image", content:"https://cimarronesdesonora.xyz/img/Cimarrones-mx.db56f6bd.png" }
      ],}
  },
    data() {
      return {
      /*metaInfo: {
        title: 'Noticia Cimarrones FC',
      meta: [
        { property:"og:title", name:"title", content:"Noticia Cimarrones FC"},
        { property:"og:image", name:"image", content:"https://cimarronesdesonora.xyz/img/Cimarrones-mx.db56f6bd.png" }
      ],
      sharing: {
        url: 'https://news.vuejs.org/issues/180',
        title: 'Valor intrinseco',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        image: 'https://cimarronesdesonora.xyz/img/Cimarrones-mx.db56f6bd.png',
        hashtags: 'vuejs,vite,javascript',
        twitterUser: 'youyuxi'
      },
      },*/
      }
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },created(){
        this.findByTitle();
    },computed: {
        url() {
        return this.$store.getters["main/baseURL"];
        },
        blogdata(){
        return this.$store.getters['blog/data']
        },
        user() {            
        return this.$store.getters['admin/getIdentity'];
        },
        /*title: {
          get() {
            let titleM = this.$route.params.title
            let title = titleM.replaceAll('-',' ')
             document.title =  title + '- Cimarrones de Sonora'
            return  title + '- Cimarrones de Sonora'
          },
          set(val) {
            document.title = val
          }
        }*/
    },
    methods: {
    ...mapActions('blog', ['findByTitle']),
    ...mapActions('blog', ['search']), 

    dataTransform: function(date){
        let fecha = date.split('/')
              let yy = fecha[2]
              let mm = fecha[1]
              let dd = fecha[0]
        return fecha = yy+'-'+mm+'-'+dd
    },    

    newsdateago: function(date){
        const dayjs = require('dayjs');
        dayjs.extend(relativeTime)
        let newsdata = dayjs(date).locale(es).fromNow(true)
        return newsdata
    },

    convertdate: function(date){
        const dayjs = require('dayjs');
        dayjs.extend(relativeTime)
        let newsdata = dayjs(date).locale(es).format('YYYY-MMMM-DD')
        let fecha = newsdata.split('-')
              let dd = fecha[2]
              let mm = fecha[1]
              let yy = fecha[0]
        return fecha = dd+' de '+mm+' del '+yy
    },

   findByTitle:async function(){
    let titleM = this.$route.params.title
      //let title = titleM.replaceAll('-',' ').replaceAll('_','/') 

       let result = await this.$store.dispatch("blog/findByTitle", {"option":"blog/url","title": titleM}); // ahora el en objeto blog se encuentra el parametro url el cual ya trae el titulo convertido.
       this.info = result
       return result;
    },   

    filterTitle: function(title){
        let result = title.replaceAll(' ','-').replaceAll('/','_')
        return result
    },

    tituloFiltrado(){
                let titleM = this.$route.params.title
                return titleM.replaceAll('-',' ').replaceAll('/','_')
    },

   /* getOpenGraph(url) {
  const encodedURL = encodeURIComponent(url)
  axios
    .get(`https://opengraph.io/api/1.1/site/${encodedURL}?app_id=xxxxxxx`)
    .then(res => { console.log(res.data.hybridGraph) })
    .catch(err => { console.log(err) })
}*/
  },


  /*titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let titleM = this.$route.params.title
                let title = titleM.replaceAll('-',' ').replaceAll('/','_')
                document.title = to.meta.title || title + '- Cimarrones de Sonora';
                  let url ='https://cimarronesdesonora.xyz/blog/titulo/'+titleM
                 document.querySelector('meta[property="og:url"').setAttribute("content", url);
                 document.querySelector('meta[property="og:title"').setAttribute("content", title);
            }
        },
    }
};
</script>
